import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MotifFormField,
  MotifFileUploader,
  MotifIcon,
  MotifFileUploaderItem
} from '@ey-xd/motif-react';
import { actionIcDescription24px } from '@ey-xd/motif-react/assets/icons';
/**
 * Returns a unique list of files
 *
 * @param {array} prevQueue - previus list of files
 * @param {array} newFiles - new list of files
 */
const addToQueueUnique = (prevQueue: any, newFiles: any) => {
  const ids = new Set(prevQueue.map((prevFile: any) => prevFile.path));
  const merged = [...prevQueue, ...newFiles.filter((newFile: any) => !ids.has(newFile.path))];
  return merged;
};
/**
 * Updates the queue with the new files dropped in
 *
 * @param {array} newFiles - new files to be added to the queue
 * @param {func} setQueue - function that updates the queue
 */
const handleDrop = (newFiles: any, setQueue: any) => {
	if(newFiles.length > 1) {
		alert("You can only upload one file at a time.")
		return;
	}
  	setQueue((prevQueue: any) => addToQueueUnique(prevQueue, newFiles));
};
/**
 * Removes a single file from the queue
 *
 * @param {array} queue - current list of files to be uploaded
 * @param {func} setQueue - function that updates the queue
 * @param {Object} removedFile - the file to be removed from the queue
 */
const removeFile = (queue: any, setQueue: any, removedFile: any) => {
  const nextQueue = queue.filter((file: any) => file.path !== removedFile.path);
  setQueue(nextQueue);
};
/**
 * Renders each item in the queue as a MotifFileUploaderItem
 *
 * @param {*} queue - current list of files to be uploaded
 * @param {*} setQueue - function that updates the queue
 */
const renderQueue = (queue: any, setQueue: any) =>
  queue.map((file: any) => (
	<UploadingItem key={file.path} file={file} queue={queue} setQueue={setQueue} />
  ));
const UploadingItem = ({ file, queue, setQueue }: any) => {
  const [progress, setProgress] = useState(0);
  const formData = new FormData();
  formData.append('files', file);
  const request = new XMLHttpRequest();
  request.open('POST', 'https://httpbin.org/post&#x27;)');
  // upload progress event
  request.upload.addEventListener('progress', reqEvent => {
	// upload progress as percentage
	const percentCompleted = (reqEvent.loaded / reqEvent.total) * 100;
	setProgress(percentCompleted);
  })
  // send POST request to server
  if (progress === 0) {
	request.send(formData);
  }
  return (
	<MotifFileUploaderItem
	  showProgress
	  key={file.path}
	  percentUploaded={progress}
	  aria-label={`Click to remove file - ${file.name}`}
	  removable
	  uploaded={progress === 100}
	  onRemove={() => removeFile(queue, setQueue, file)}
	  fileName={file.name}
	  fileSize={file.size}
	  fileIcon={<MotifIcon src={actionIcDescription24px} />}
	>
	  {file.name}
	</MotifFileUploaderItem>
  );
};
/**
 * Mock form submission. Updates the queue and uploaded state arrays
 *
 * @param {Object} event - submit event
 * @param {Object} state - current state
 */
const FileUpload = ({
  handleDrop,
  fileIndex,
  accept = [
    ".pdf",
    ".xls",
    ".xlsx",
    ".doc",
    ".docx",
    ".ppt",
    ".pptx",
    ".txt",
    ".png",
    ".jpeg",
    ".bmp",
    ".zip",
  ],
}: any) => {
  const [queue, setQueue] = useState([]);
  return (
    <>
      <MotifFormField>
        <MotifFileUploader
          accept={accept}
          onDrop={(event: any) => handleDrop(event, fileIndex)}
          id="my-id"
          label="Drag and drop a document or upload"
        >
          {renderQueue(queue, setQueue)}
        </MotifFileUploader>
      </MotifFormField>
    </>
  );
};
export default FileUpload;
